var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (  _vm.data.properties.filed_content != 'Hide')?_c('div',{staticClass:"form-group",style:(_vm.getElementStyle())},[(!_vm.data.properties.hideLabel)?_c('el-row',[_c('el-col',[_c('span',[(_vm.hasLabel)?_c('label',{style:(_vm.getLabelStyles),attrs:{"for":"text"}},[_vm._v(" "+_vm._s(_vm.data.label)+" ")]):_vm._e()]),(_vm.data.validations.required)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]):_vm._e(),(_vm.data.description)?_c('span',{staticStyle:{"color":"#c0c4cc","font-weight":"bold"}},[_c('el-tooltip',{attrs:{"content":_vm.data.description,"placement":"top","effect":"dark"}},[_c('i',{staticClass:"el-icon-info"})])],1):_vm._e()])],1):_vm._e(),(!_vm.isView)?_c('el-row',[(!_vm.isView)?_c('el-input',{style:(_vm.getStyle),attrs:{"type":"email","placeholder":_vm.data.placeholder || _vm.data.label,"required":_vm.data.validations.required,"disabled":_vm.checkReadonly(),"minlength":_vm.data.properties.minlength_value ? _vm.data.properties.minlength_value : 8,"maxlength":_vm.data.properties.maxlength_value
          ? _vm.data.properties.maxlength_value
          : 256,"suffix-icon":_vm.data.properties.el_icon},on:{"blur":_vm.validateEmail},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_vm._e()],1):_vm._e(),_c('el-row',{attrs:{"align":"middle"}},[_c('el-col',{attrs:{"span":_vm.showLabel
      &&
      !_vm.isDefalutPos
      ?
      12
      :
      24}},[(_vm.hasError)?_c('p',{staticStyle:{"color":"red","font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")]):_vm._e(),(_vm.isView)?_c('p',{style:(_vm.getStyle)},[(_vm.form[_vm.data.key])?[_vm._v(_vm._s(_vm.form[_vm.data.key]))]:[_vm._v("-")]],2):_vm._e()])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }